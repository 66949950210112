<template>
  <div class="info animate">
    <div>Ferienprogramm für Kinder, Jugendliche und Familien</div>
    <div>Am Gutshaus Welzin zwischen Lübz und Goldberg</div>
    <div>11. Juli bis 13. August 2022 - Kostenlos und draußen</div>
  </div>
</template>

<script>
export default {
  name: "InfoContainer",
  data() {
    return {
      count: 0,
      timeout: 5000,
      container: undefined,
    };
  },
  methods: {
    animate() {
      this.container.forEach((el, index) => {
        if (index == this.count) {
          el.classList.add("show");
        } else {
          el.classList.remove("show");
        }
      });
      if (this.count + 1 < this.container.length) this.count++;
      else this.count = 0;
      setTimeout(this.animate, this.timeout);
    },
  },
  mounted() {
    this.container = document.querySelectorAll(".info div");

    this.animate();
  },
};
</script>

<style lang="css" scoped>
.info {
  position: relative;
  font-weight: 600;
  text-align: center;
  height: 36px;
  user-select: none;
}

.info div {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 6px;
  color: #000;
  opacity: 1;
  mix-blend-mode: multiply;
  transition: opacity 0.2s;
  opacity: 0;
}

.info .show {
  animation-name: fade;
  animation-duration: 5s;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (min-width: 600px) {
  .info {
    height: 64px;
  }

  .info div {
    top: 12px;
    font-size: 1.6rem;
  }
}
</style>
