<template>
  <div ref="matter" id="matter"></div>
</template>

<script>
import { Engine, Render, World, Bodies, Body, Runner } from "matter-js";

export default {
  name: "ClickerOverview",
  props: ["clicked"],
  data() {
    return {
      engine: undefined,
      runner: undefined,
      render: undefined,
      interval: undefined,
      when: undefined,
    };
  },
  methods: {
    createBody(e = this.clicked) {
      const randomInRange = (min, max) => Math.random() * (max - min) + min;
      const randomIntInRange = (min, max) =>
        Math.floor(Math.random() * (max - min + 1) + min);

      const left = e.x - 10;
      const right = e.x + 10;
      const top = e.y - 10;
      const bottom = e.y + 10;

      const x = randomInRange(left, right);
      const y = randomInRange(top, bottom);
      const radius = randomInRange(10, 50);
      const sides = randomIntInRange(3, 6);
      const body = Bodies.polygon(x, y, sides, radius, {
        friction: -1,
        frictionAir: radius * -1 * 0.00001,
        restitution: 1,
        inertia: Infinity,
        rotationSpeed: 1,
        render: {
          fillStyle: "rgba(255,255,255,1)",
          strokeStyle: "none",
          lineWidth: 0,
        },
      });

      Body.setAngle(body, body.angle + body.rotationSpeed);
      World.add(this.engine.world, body);
    },
  },
  mounted() {
    this.engine = Engine.create();
    this.engine.world.gravity.y = 2;

    this.render = Render.create({
      element: document.getElementById("matter"),
      engine: this.engine,
      options: {
        width: window.innerWidth,
        height: window.innerHeight,
        wireframes: false,
        background: "",
      },
    });

    Render.run(this.render);
    this.runner = Runner.create();
    Runner.run(this.runner, this.engine);
  },
  watch: {
    clicked: {
      handler(n) {
        if (!this.when) {
          this.when = new Date();
          this.engine.world.bodies.forEach((body) => {
            if (body.position.y > window.innerHeight)
              World.remove(this.engine.world, body);
          });
          this.createBody(n);
          this.interval = setInterval(() => {
            this.createBody(n);
          }, 10);
          setTimeout(() => {
            clearInterval(this.interval);
          }, 250);

          setTimeout(() => {
            this.when = undefined;
          }, 500);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="css" scoped>
#matter {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 999999999999999;
}
</style>
