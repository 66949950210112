<template>
  <div class="anmeldungBtn" v-if="free">
    <div class="free">Noch {{ free }} freie Plätze</div>
    <button
      @click="show"
      title="Hier klicken um dich anzumelden!"
      class="button"
    >
      Jetzt anmelden!
    </button>
  </div>
  <div v-else>
    <strong>Leider keinen freien Plätze mehr!</strong>
  </div>
</template>

<script>
export default {
  name: "FestivalAnmeldung",
  props: ["item"],
  data() {
    return {
      free: undefined,
    };
  },
  methods: {
    show() {
      this.$store.state.anmeldung = this.item;
    },
  },
  created() {
    this.free = this.item.teilnehmer - this.item.tn;
  },
};
</script>

<style lang="css">
.anmeldungBtn {
  display: grid;
  grid-template-columns: auto min-content;
  border: 0;
  white-space: nowrap;
  background: #fff;
  color: black;
  border: 2px solid black;
  mix-blend-mode: multiply;
  box-sizing: border-box;
}

.anmeldungBtn .free {
  display: grid;
  height: 100%;
  place-items: center;
  font-weight: 600;
  user-select: none;
  margin: 0 12px;
}

.anmeldungForm {
  position: absolute;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 10000000000;
}

.anmeldungForm .form {
  padding: 24px;
  width: 80vw;
  background: white;
}

.anmeldungForm label {
  display: block;
  margin-bottom: 6px;
}

/* .anmeldungForm input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 1px solid black;
  outline: none;
  padding: 12px;
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 24px;
} */

/* .anmeldungForm button {
  margin-right: 3px;
} */

@media screen and (min-width: 600px) {
  .anmeldungBtn {
    display: inline-grid;
  }
}
</style>
