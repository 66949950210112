<template>
  <div>
    <Info />
    <header class="">
      <div class="title">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 2200 639"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve"
          xmlns:serif="http://www.serif.com/"
          style="
            fill-rule: evenodd;
            clip-rule: evenodd;
            stroke-linejoin: round;
            stroke-miterlimit: 2;
          "
          class="header animate_svg"
        >
          <g transform="matrix(1,0,0,1,-3282.63,-645.375)">
            <g id="Titel" transform="matrix(1,0,0,1.02085,654.672,522.85)">
              <g transform="matrix(10.7761,0,0,10.7761,-6900.39,-13748.3)">
                <path
                  d="M1019.65,1315.97L1002.26,1315.97L1002.26,1312.08L1008.93,1305.21C1010.95,1303.14 1012.37,1301.51 1013.18,1300.34C1014,1299.17 1014.41,1297.88 1014.41,1296.48C1014.41,1295.07 1014.02,1294.07 1013.23,1293.47C1012.43,1292.87 1011.14,1292.57 1009.34,1292.57C1007.65,1292.57 1005.78,1292.76 1003.74,1293.14L1002.71,1293.31L1002.43,1289.71C1005.07,1288.92 1007.81,1288.52 1010.65,1288.52C1016.29,1288.52 1019.12,1291.03 1019.12,1296.05C1019.12,1298.01 1018.69,1299.71 1017.83,1301.16C1016.97,1302.61 1015.43,1304.35 1013.23,1306.39L1007.46,1312L1019.65,1312L1019.65,1315.97Z"
                  style="fill-rule: nonzero"
                  class="number"
                />
                <path
                  d="M1026.19,1291.83C1027.91,1289.62 1030.47,1288.52 1033.88,1288.52C1037.29,1288.52 1039.84,1289.61 1041.53,1291.79C1043.22,1293.98 1044.07,1297.58 1044.07,1302.61C1044.07,1307.64 1043.21,1311.2 1041.51,1313.29C1039.8,1315.37 1037.26,1316.42 1033.86,1316.42C1030.46,1316.42 1027.92,1315.38 1026.21,1313.31C1024.51,1311.24 1023.65,1307.68 1023.65,1302.65C1023.65,1297.62 1024.5,1294.02 1026.19,1291.83ZM1038.09,1294.74C1037.25,1293.24 1035.84,1292.49 1033.88,1292.49C1031.92,1292.49 1030.51,1293.23 1029.65,1294.72C1028.79,1296.2 1028.36,1298.82 1028.36,1302.55C1028.36,1306.29 1028.8,1308.87 1029.69,1310.3C1030.57,1311.73 1031.98,1312.45 1033.9,1312.45C1035.82,1312.45 1037.21,1311.73 1038.07,1310.3C1038.93,1308.87 1039.36,1306.29 1039.36,1302.55C1039.36,1298.82 1038.94,1296.21 1038.09,1294.74Z"
                  style="fill-rule: nonzero"
                  class="number"
                />
                <path
                  d="M1065.46,1315.97L1048.07,1315.97L1048.07,1312.08L1054.74,1305.21C1056.76,1303.14 1058.18,1301.51 1058.99,1300.34C1059.81,1299.17 1060.22,1297.88 1060.22,1296.48C1060.22,1295.07 1059.83,1294.07 1059.04,1293.47C1058.24,1292.87 1056.95,1292.57 1055.15,1292.57C1053.46,1292.57 1051.59,1292.76 1049.55,1293.14L1048.52,1293.31L1048.24,1289.71C1050.88,1288.92 1053.62,1288.52 1056.46,1288.52C1062.1,1288.52 1064.92,1291.03 1064.92,1296.05C1064.92,1298.01 1064.5,1299.71 1063.64,1301.16C1062.78,1302.61 1061.24,1304.35 1059.04,1306.39L1053.27,1312L1065.46,1312L1065.46,1315.97Z"
                  style="fill-rule: nonzero"
                  class="number"
                />
                <path
                  d="M1088.36,1315.97L1070.98,1315.97L1070.98,1312.08L1077.64,1305.21C1079.66,1303.14 1081.08,1301.51 1081.9,1300.34C1082.72,1299.17 1083.13,1297.88 1083.13,1296.48C1083.13,1295.07 1082.73,1294.07 1081.94,1293.47C1081.15,1292.87 1079.85,1292.57 1078.05,1292.57C1076.36,1292.57 1074.5,1292.76 1072.45,1293.14L1071.43,1293.31L1071.14,1289.71C1073.79,1288.92 1076.53,1288.52 1079.36,1288.52C1085.01,1288.52 1087.83,1291.03 1087.83,1296.05C1087.83,1298.01 1087.4,1299.71 1086.54,1301.16C1085.68,1302.61 1084.15,1304.35 1081.94,1306.39L1076.17,1312L1088.36,1312L1088.36,1315.97Z"
                  style="fill-rule: nonzero"
                  class="number"
                />
              </g>
              <g transform="matrix(1.35568,0,0,0.731295,3303.91,-399.274)">
                <path
                  d="M-498.604,1139.87L-441.468,719.315L-366.751,719.315L-309.277,1139.87L-347.142,1139.87L-359.651,1049.62L-448.567,1049.62L-461.076,1139.87L-498.604,1139.87ZM-412.392,776.412L-441.129,989.45L-367.089,989.45L-395.488,776.412L-412.392,776.412Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M-219.009,826.142C-207.063,826.142 -192.977,829.007 -176.749,834.737L-168.297,837.806L-169.649,890.606C-187.455,887.331 -200.64,885.694 -209.205,885.694C-226.334,885.694 -237.829,892.652 -243.689,906.568C-249.549,920.484 -252.479,946.679 -252.479,985.153C-252.479,1023.63 -249.662,1050.23 -244.027,1064.96C-238.392,1079.7 -226.672,1087.07 -208.867,1087.07L-169.311,1082.16L-168.297,1135.57C-191.061,1142.94 -208.19,1146.62 -219.685,1146.62C-245.38,1146.62 -263.467,1134.03 -273.948,1108.86C-284.428,1083.69 -289.668,1042.45 -289.668,985.153C-289.668,927.851 -284.146,887.024 -273.102,862.671C-262.058,838.318 -244.027,826.142 -219.009,826.142Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M-41.854,889.992L-88.509,889.992L-88.509,1025.06C-88.509,1050.03 -87.495,1066.6 -85.466,1074.79C-83.438,1082.97 -78.254,1087.07 -69.915,1087.07L-42.192,1085.22L-40.501,1138.64C-55.602,1143.96 -67.097,1146.62 -74.986,1146.62C-94.144,1146.62 -107.273,1138.64 -114.372,1122.68C-121.472,1106.71 -125.022,1076.63 -125.022,1032.43L-125.022,889.992L-146.659,889.992L-146.659,832.895L-125.022,832.895L-125.022,743.873L-88.509,743.873L-88.509,832.895L-41.854,832.895L-41.854,889.992Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M-11.764,1139.87L-11.764,832.895L25.087,832.895L25.087,1139.87L-11.764,1139.87ZM-11.764,780.71L-11.764,710.106L25.087,710.106L25.087,780.71L-11.764,780.71Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M78.842,866.048C90.562,839.444 109.946,826.142 136.992,826.142C164.039,826.142 183.366,839.444 194.973,866.048C206.581,892.652 212.385,932.558 212.385,985.767C212.385,1038.97 206.75,1079.09 195.48,1106.1C184.211,1133.11 164.715,1146.62 136.992,1146.62C109.269,1146.62 89.773,1133.11 78.504,1106.1C67.234,1079.09 61.6,1038.97 61.6,985.767C61.6,932.558 67.347,892.652 78.842,866.048ZM106.565,1065.58C111.749,1081.95 121.891,1090.14 136.992,1090.14C152.093,1090.14 162.236,1081.95 167.42,1065.58C172.604,1049.21 175.196,1022.4 175.196,985.153C175.196,947.907 172.434,921.507 166.912,905.954C161.39,890.401 151.417,882.624 136.992,882.624C122.567,882.624 112.594,890.401 107.072,905.954C101.55,921.507 98.789,947.907 98.789,985.153C98.789,1022.4 101.381,1049.21 106.565,1065.58Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M285.749,1139.87L248.898,1139.87L248.898,832.895L285.41,832.895L285.41,851.927C301.864,834.737 317.416,826.142 332.066,826.142C354.605,826.142 369.988,837.704 378.214,860.829C386.441,883.954 390.554,922.121 390.554,975.33L390.554,1139.87L354.041,1139.87L354.041,977.171C354.041,944.019 352.069,920.484 348.125,906.568C344.181,892.652 336.123,885.694 323.952,885.694C312.457,885.694 301.413,889.787 290.82,897.973L285.749,901.656L285.749,1139.87Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M-410.702,1190.71C-439.326,1190.71 -453.639,1208.93 -453.639,1245.35C-453.639,1264.18 -450.258,1277.59 -443.496,1285.57C-436.734,1293.55 -421.802,1303.37 -398.7,1315.04C-375.598,1326.7 -359.313,1340.62 -349.847,1356.78C-340.381,1372.95 -335.648,1398.43 -335.648,1433.22C-335.648,1477.42 -342.578,1510.47 -356.44,1532.37C-370.301,1554.27 -389.177,1565.22 -413.069,1565.22C-432.227,1565.22 -453.413,1561.12 -476.628,1552.94L-488.799,1548.64L-485.08,1494C-454.653,1501.37 -431.438,1505.05 -415.435,1505.05C-387.487,1505.05 -373.513,1482.54 -373.513,1437.52C-373.513,1419.92 -376.668,1406.92 -382.979,1398.53C-389.29,1390.14 -403.49,1380.93 -425.578,1370.9C-447.666,1360.88 -464.119,1347.06 -474.938,1329.46C-485.756,1311.86 -491.166,1284.03 -491.166,1245.97C-491.166,1207.9 -484.348,1179.15 -470.712,1159.71C-457.076,1140.27 -438.199,1130.55 -414.083,1130.55C-396.728,1130.55 -376.218,1134.23 -352.552,1141.6L-340.719,1145.28L-343.762,1200.54C-375.316,1193.99 -397.629,1190.71 -410.702,1190.71Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M-290.345,1284.64C-278.624,1258.04 -259.241,1244.74 -232.194,1244.74C-205.148,1244.74 -185.821,1258.04 -174.213,1284.64C-162.606,1311.25 -156.802,1351.16 -156.802,1404.36C-156.802,1457.57 -162.437,1497.68 -173.706,1524.7C-184.975,1551.71 -204.471,1565.22 -232.194,1565.22C-259.917,1565.22 -279.413,1551.71 -290.683,1524.7C-301.952,1497.68 -307.587,1457.57 -307.587,1404.36C-307.587,1351.16 -301.839,1311.25 -290.345,1284.64ZM-262.622,1484.18C-257.438,1500.55 -247.295,1508.73 -232.194,1508.73C-217.093,1508.73 -206.951,1500.55 -201.767,1484.18C-196.583,1467.81 -193.991,1441 -193.991,1403.75C-193.991,1366.5 -196.752,1340.11 -202.274,1324.55C-207.796,1309 -217.769,1301.22 -232.194,1301.22C-246.619,1301.22 -256.593,1309 -262.115,1324.55C-267.637,1340.11 -270.398,1366.5 -270.398,1403.75C-270.398,1441 -267.806,1467.81 -262.622,1484.18Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M-83.438,1558.46L-120.289,1558.46L-120.289,1251.49L-83.776,1251.49L-83.776,1270.53C-67.773,1253.33 -53.123,1244.74 -39.825,1244.74C-20.216,1244.74 -5.904,1254.77 3.111,1274.82C23.622,1254.77 44.019,1244.74 64.304,1244.74C84.589,1244.74 98.901,1256.1 107.241,1278.81C115.58,1301.53 119.75,1339.9 119.75,1393.93L119.75,1558.46L83.237,1558.46L83.237,1395.77C83.237,1362.62 81.378,1339.08 77.659,1325.17C73.94,1311.25 66.22,1304.29 54.5,1304.29C44.357,1304.29 33.426,1308.38 21.706,1316.57L15.959,1320.87C17.762,1329.05 18.663,1355.25 18.663,1399.45L18.663,1558.46L-17.85,1558.46L-17.85,1400.68C-17.85,1364.25 -19.653,1339.08 -23.259,1325.17C-26.865,1311.25 -34.754,1304.29 -46.925,1304.29C-58.194,1304.29 -68.675,1308.38 -78.367,1316.57L-83.438,1320.25L-83.438,1558.46Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M199.876,1558.46L163.025,1558.46L163.025,1251.49L199.537,1251.49L199.537,1270.53C215.54,1253.33 230.19,1244.74 243.488,1244.74C263.097,1244.74 277.409,1254.77 286.425,1274.82C306.935,1254.77 327.333,1244.74 347.618,1244.74C367.903,1244.74 382.215,1256.1 390.554,1278.81C398.894,1301.53 403.063,1339.9 403.063,1393.93L403.063,1558.46L366.55,1558.46L366.55,1395.77C366.55,1362.62 364.691,1339.08 360.972,1325.17C357.253,1311.25 349.534,1304.29 337.813,1304.29C327.671,1304.29 316.74,1308.38 305.019,1316.57L299.272,1320.87C301.075,1329.05 301.977,1355.25 301.977,1399.45L301.977,1558.46L265.464,1558.46L265.464,1400.68C265.464,1364.25 263.661,1339.08 260.054,1325.17C256.448,1311.25 248.559,1304.29 236.388,1304.29C225.119,1304.29 214.638,1308.38 204.947,1316.57L199.876,1320.25L199.876,1558.46Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M566.695,1503.21L576.162,1501.37L576.838,1551.1C551.144,1560.51 528.379,1565.22 508.545,1565.22C483.527,1565.22 465.552,1552.73 454.621,1527.77C443.69,1502.8 438.224,1462.89 438.224,1408.05C438.224,1299.17 462.679,1244.74 511.588,1244.74C558.919,1244.74 582.585,1291.6 582.585,1385.33L580.219,1433.22L475.413,1433.22C475.638,1458.6 478.681,1477.22 484.541,1489.09C490.401,1500.96 501.333,1506.89 517.335,1506.89C533.338,1506.89 549.791,1505.66 566.695,1503.21ZM546.41,1381.65C546.41,1351.36 543.762,1330.18 538.465,1318.11C533.169,1306.03 524.21,1299.99 511.588,1299.99C498.966,1299.99 489.782,1306.34 484.034,1319.03C478.287,1331.71 475.3,1352.59 475.075,1381.65L546.41,1381.65Z"
                  style="fill: white; fill-rule: nonzero"
                />
                <path
                  d="M618.084,1558.46L618.084,1251.49L654.597,1251.49L654.597,1288.33C673.755,1265.82 692.913,1251.29 712.071,1244.74L712.071,1311.66C692.688,1318.62 676.122,1327.62 662.373,1338.67L654.935,1344.2L654.935,1558.46L618.084,1558.46Z"
                  style="fill: white; fill-rule: nonzero"
                />
              </g>
            </g>
          </g>
        </svg>

        <Yay />
      </div>

      <div class="intro animate">
        <h2>Sommerferien 2022 in Welzin &mdash; riesig was los!</h2>

        <p>
          <strong
            >Jeden Tag in den Sommerferien gibt es Programm für Kids und
            Jugendliche! Immer 9.00 - 17.00 Uhr, immer in Welzin am
            Gutshaus.</strong
          >
        </p>
        <p>
          Workshops, Neues lernen, zwischendurch Chillen, lecker Essen, andere
          Kinder und Jugendliche treffen und einfach Spass haben! Wir bauen eine
          Trampwall, drehen einen Film, machen Musik vieles mehr. Egal, ob Ihr
          nur an einem Tag oder gleich mehrere Wochen mitmachen wollt - alles
          ist möglich! Meldet Euch für jeden Tag einzeln an, an dem Ihr dabei
          sein wollt. Wir sorgen für Mittagessen, Getränke und Pausensnacks.
          Klickt Ihr auf einen bestimmten Tag, seht Ihr alles, was Ihr wissen
          müsst, um Euch zu entscheiden.
        </p>
        <p>
          <strong>
            Samstags ist Familienprogramm für alle, ohne Anmeldung - einfach
            vorbeikommen!
          </strong>
        </p>

        <p>
          <strong
            >Die Teilnahme ist kostenlos und wir freuen uns auf Euch!</strong
          >
        </p>

        <p>
          Und das ist sonst noch in Welzin diesen Sommer los:
          <a href="https://welziner-kultur-sommer.de/" target="top"
            >welziner-kultur-sommer.de</a
          >
        </p>
        <Social />
      </div>
    </header>
  </div>
</template>

<script>
import Yay from "@/components/Yay.vue";
import Info from "@/components/Info.vue";
import Social from "@/components/Social.vue";

export default {
  name: "HeaderContainer",
  components: { Yay, Info, Social },
  methods: {
    navigate() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="css" scoped>
header {
  position: relative;
  padding: 12px;
  padding-top: 24px;
  padding-bottom: 0;
  overflow: hidden;
  user-select: none;
  background: url(@/assets/bg.png);
  background-size: 80px;
  z-index: 10000000;
}

header .title {
  width: 100%;
  margin: 0;
  position: relative;
}

header .header {
  position: relative;
  z-index: 1000000;
}

header h2 {
  font-size: 2.2rem;
}

header h2 span {
  font-size: 1.4rem;
}

header .intro {
  margin: 12px 0;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 25px;
  opacity: 0.9;
}

header .intro h2 {
  margin-top: -6px;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.2rem;
}

header .intro p:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 600px) {
  header {
    background-size: 100px;
  }

  header .intro {
    box-sizing: border-box;
    margin: 48px auto;
    padding: 36px;
    margin-bottom: 64pxpx;
    max-width: 1200px;
    font-size: 1.2rem;
  }

  header .title {
    display: block;
    box-sizing: border-box;
    padding-top: 24px;
    max-width: 1200px;
    margin: auto;
  }
}
</style>
