<template>
  <div class="Social">
    <div>Weitersagen!</div>
    <div class="sogr">
      <div
        class="twitter"
        @click="share(1)"
        title="ActionSommer auf Twitter teilen!"
      >
        <img src="@/assets/twitter.svg" alt="Twitter" />
      </div>
      <div
        class="facebook"
        @click="share(2)"
        title="ActionSommer auf Facebook teilen!"
      >
        <img src="@/assets/facebook.svg" alt="Facebook" />
      </div>

      <div class="mail" @click="share(3)" title="ActionSommer per Mail teilen!">
        <img src="@/assets/at-sign.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialContainer",
  methods: {
    share(id) {
      var width = 650;
      var height = 450;
      if (!window.screenLeft) {
        window.screenLeft = window.screenX;
        window.screenTop = window.screenY;
      }

      var leftPos = window.screenLeft + window.innerWidth / 2 - width / 2;
      var topPos = window.screenTop + window.innerHeight / 2 - height / 2;
      var url;

      const text =
        "ActionSommer 2022 – Familienprogramm für Kinder, Jugendliche und Familien am Gutshaus Welzin (zwischen Lübz und Goldberg) vom 11. Juli bis 13. August 2022 - Kostenlos & draußen!";
      const here = window.location.href;

      if (id == 1)
        url =
          "https://twitter.com/intent/tweet?url=" +
          encodeURIComponent(here) +
          "&text=" +
          encodeURIComponent(text);

      if (id == 2)
        url =
          "https://www.facebook.com/sharer/sharer.php?u=" +
          encodeURIComponent(here);

      if (id == 3)
        url =
          "mailto:?" +
          "subject=" +
          encodeURIComponent("Sommerferien? ActionSommer 2022!") +
          "&body=" +
          encodeURIComponent(text + "\n\nAlle Infos unter www.actionsommer.de");

      if (id != 3)
        window.open(
          url,
          "",
          "toolbar=no,scrollbars=auto,left=" +
            leftPos +
            ",top=" +
            topPos +
            ",width=" +
            width +
            ",height=" +
            height
        );

      if (id == 3) {
        const a = document.createElement("a");
        a.href = url;
        a.click();
      }
    },
  },
};
</script>

<style lang="css" scoped>
.Social {
  display: inline-grid;
  width: 100%;
  box-sizing: border-box;
  font-weight: bold;
  border: 2px solid black;
  border-radius: 15px;
  text-align: center;
  font-size: 1rem;
  background: black;
  color: white;
  padding: 12px;
  place-items: center;
  grid-template-rows: min-content auto;
  grid-gap: 12px;
  mix-blend-mode: multiply;
  opacity: 0.9;
  user-select: none;
  margin-top: 12px;
}

.Social .sogr {
  display: grid;
  grid-template-columns: min-content min-content min-content;
  grid-gap: 8px;
}

.Social .sogr div {
  background: white;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  overflow: hidden;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-bottom: 6px;
}

.Social img {
  width: 20px;
}

@media screen and (min-width: 600px) {
  .Social {
    display: inline-grid;
    width: auto;
  }
}
</style>
