<template>
  <div class="container kanu weekend_event">
    <div class="content" v-if="item">
      <div class="date">Samstag, 6. August 2022 - 10 Uhr</div>
      <h2>{{ item.title }}</h2>
      <div class="tags">
        <div class="tag">10-14 Jahre</div>
        <div class="tag">Kostenlos!</div>
      </div>
      <div v-html="item.content"></div>
    </div>
    <div class="link">
      <FestivalAnmeldung v-if="item" :item="item" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FestivalAnmeldung from "@/components/FestivalAnmeldung.vue";

export default {
  name: "KanuContainer",
  components: { FestivalAnmeldung },
  data() {
    return {
      item: undefined,
    };
  },
  methods: {
    ...mapActions(["db"]),
    fetch() {
      let payload = { q: { id: { $eq: 100 } } };
      this.db({ table: "programm", payload }).then((res) => {
        this.item = res.data.results[0];
      });
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="css" scoped>
.kanu {
  grid-area: kanu;
  position: relative;
  padding: 24px;
}
</style>
