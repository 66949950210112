<template>
  <div class="anmeldung animate">
    <div class="bg"><img src="@/assets/lines.svg" /></div>
    <div class="anmeldung_container">
      <div class="close" @click="cancel" title="Fenster schliessen">
        <img src="@/assets/x-circle.svg" />
      </div>
      <div class="form" v-if="!success && !error">
        <h2>Deine Anmeldung</h2>
        Du meldest Dich an für:
        <h3>
          <template v-if="item.festival_title">
            {{ item.festival_title }}<br />
          </template>
          {{ item.title }} <br />
          {{ $dayjs(item.start).format("DD. MMMM YYYY") }} -
          {{ $dayjs(item.start).format("HH:mm") }} Uhr
        </h3>
        <form @submit.prevent="register">
          <label>Dein Name</label>
          <input type="text" v-model="teilnehmer.name" required />

          <label>Deine Telefonnummer</label>
          <input type="text" v-model="teilnehmer.phone" required />

          <label>Deine E-Mail-Adresse</label>
          <input type="email" v-model="teilnehmer.email" required />

          <button type="submit" class="button block">Jetzt anmelden!</button>
        </form>
        <p>
          Nach Deiner Anmeldung bekommst Du alle wichtigen Informationen per
          E-Mail!
        </p>
      </div>

      <div class="form" v-if="success">
        <h2>Hurra!</h2>
        <p>
          Danke für deine Anmeldung! Du erhältst demnächst alle wichtigen
          Informationen per E-Mail.
        </p>
        <br />
        <button type="button" @click="cancel" class="button block">
          Fenster schliessen
        </button>
      </div>

      <div class="form" v-if="error">
        <h2>Ups!</h2>
        <p>Da ist etwas schiefgegangen!</p>
        <button type="button" @click="cancel" class="button block">
          Fenster schliessen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AnmeldungContainer",
  data() {
    return {
      success: undefined,
      error: undefined,
      teilnehmer: {},
    };
  },
  computed: {
    item() {
      return this.$store.state.anmeldung;
    },
  },
  methods: {
    ...mapActions(["db"]),
    cancel() {
      this.$store.state.anmeldung = undefined;
    },
    register(e) {
      e.preventDefault();

      this.db({
        table: "anmeldungen",
        payload: { q: { id: { $eq: this.item.id } } },
      }).then((res) => {
        if (this.item.teilnehmer - res.data.results[0].tn > 0) {
          // REGISTER
          let payload = this.teilnehmer;
          this.teilnehmer.event_id = this.item.id;
          this.db({ table: "anmeldung", action: "post", payload }).then(() => {
            this.success = true;
          });
        } else {
          // AUSGEBUCHT
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.anmeldung {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  z-index: 9999999999999999;
  display: grid;
  overflow: hidden;
}

.anmeldung p {
  margin-top: 12px;
  margin-bottom: 0;
  font-weight: 600;
}

.anmeldung .bg {
  display: none;
}

.anmeldung .anmeldung_container {
  position: relative;
  padding: 12px;
  background: white;
  margin: 12px;
}

.anmeldung .close {
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
}

.anmeldung .close img {
  width: 30px;
}

.anmeldung .form {
}

.anmeldung label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 6px;
}

.anmeldung input {
  display: block;
  font-size: 1rem;
  width: 100%;
  border: 2px solid black;
  box-sizing: border-box;
  padding: 12px 8px;
  margin-bottom: 12px;
  outline: none;
  border-radius: 5px;
}

@media screen and (min-width: 600px) {
  .anmeldung {
    display: grid;
    place-items: center;
  }

  .anmeldung .bg {
    position: absolute;
    display: block;
    top: 0;
    width: 2000px;
    transform: rotate(45deg) translateY(30%);
    opacity: 0.7;
  }

  .anmeldung .anmeldung_container {
    position: relative;
    padding: 24px;
    background: white;
    width: 50%;
    max-width: 800px;
  }
}
</style>
