<template>
  <div class="container">
    <div class="title">
      <h2>{{ item.title }}</h2>
    </div>
    <div class="date">{{ date }}</div>

    <div class="clicker" @click="navigate" clicker="true"></div>
  </div>
</template>

<script>
// FIXME ALTERSGRUPPE ANZEIGEN
export default {
  props: ["item"],
  computed: {
    date() {
      return (
        this.$dayjs(this.item.start).format("DD.") +
        "–" +
        this.$dayjs(this.item.end).format("DD. MMMM YYYY")
      );
    },
  },
  methods: {
    navigate() {
      this.$router.push({ path: "/programm/" + this.item.slug });
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  cursor: pointer;
  overflow: hidden;
  background: black;
  transition: all 1s;
}

.container h2 {
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3rem;
  margin-bottom: 12px;
}

.container h3 {
  font-size: 1.4rem;
  font-weight: 400;
}

.container .title {
  position: absolute;
  top: 24px;
  left: 24px;
}

.container .date {
  position: absolute;
  bottom: 24px;
  left: 24px;
  font-size: 2rem;
  line-height: 2rem;
}

.clicker {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 980px) {
  .container h2 {
    top: 20px;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .container .date {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}

@media screen and (min-width: 1200px) {
  .container .date {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  .container h2 {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
}
</style>
