<template>
  <transition name="slide">
    <Anmeldung v-if="$store.state.anmeldung" />
  </transition>
  <router-view :key="$route.params" />
  <Navigation />
  <Clicker :clicked="clicked" />
</template>
<script>
import Clicker from "@/components/Clicker.vue";
import Navigation from "@/components/Navigation.vue";
import Anmeldung from "@/components/Anmeldung.vue";

export default {
  components: { Navigation, Clicker, Anmeldung },
  data() {
    return {
      clicked: undefined,
    };
  },
  mounted() {
    window.addEventListener("click", (e) => {
      if (e.target && e.target.getAttribute("clicker") == "true")
        this.clicked = e;
    });
  },
};
</script>

<style lang="css">
.page {
  position: relative;
  width: 100vw;
  top: 0;
  left: 0;
  transition: opacity 1s linear;
}
</style>
