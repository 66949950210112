<template>
  <footer>
    <!-- WORKSHOP INFOS -->
    <section class="workshops">
      <h2>Workshops von Montag bis Freitag<br />Immer von 9-17 Uhr</h2>

      <p>
        <strong>Alle Workshops</strong> sind <strong>kostenlos</strong>, inkl.
        Mittagessen, Snacks und Getränken. Es sind
        <strong>keine Vorkenntnisse erforderlich</strong>. Bitte beachte bei der
        Anmeldung die <strong>angegebenen Altersgruppen.</strong>
      </p>
    </section>

    <!-- FAMILIENPROGRAMM -->
    <section class="familienprogramm">
      <h2>Familienprogramm, immer samstags<br />Ab 16 Uhr</h2>
      <p>
        <strong>Kostenlos & draußen</strong> und
        <strong>ohne Anmeldung.</strong> Im Rahmen des Welziner Kultursommers
        finden abends weitere Konzerte statt.
      </p>
      <a href="https://welziner-kultur-sommer.de" target="top"
        >www.welziner-kultur-sommer.de</a
      >
    </section>

    <!-- INFOS -->
    <section class="infos infos1">
      <div>
        <h2>Veranstaltungsort & Anfahrt</h2>
        <p>Alle Veranstaltungen finden statt am</p>
        <h3>Gutshaus Welzin<br />An der Eiche 13<br />19386 Passow</h3>

        <div class="weg">
          <div>
            <img src="@/assets/map-pin.svg" alt="Google Maps" />
            <a
              href="https://goo.gl/maps/ADC3yy97oZmhxUcv9"
              class="nolink"
              target="top"
              title="Auf Google Maps anzeigen"
            >
              Google Maps
            </a>
          </div>
          <div>
            <img src="@/assets/train.svg" alt="Fahrplan" />
            <a
              href="https://www.odeg.de/fileadmin/user_upload/Unternehmensseite/linien-fahrplaene/liniennetz-fahrplaene/WEB_RB15_RB19_MAR2022_296x240_x76498.pdf"
              class="nolink"
              target="top"
              title="RB19 Fahrplan anzeigen"
            >
              RB19 (Haltestelle Passow)
            </a>
          </div>
        </div>
      </div>
      <div class="map">
        <img src="@/assets/map.svg" />
      </div>
    </section>
    <section class="infos infos2">
      <h2>Veranstalter & Kontakt</h2>
      <p>
        Der ActionSommer 2022 mit allen Workshoptagen und Familienprogrammen
        wird veranstaltet von Actiontouren - leben.lernen. e.V. Wir sind ein
        Verein aus Berlin und seit 2010 am alten Gutshaus in Welzin mit Kindern
        und Jugendlichen aktiv.
      </p>

      <div class="kontakt">
        <div>
          <img src="@/assets/mail.svg" alt="E-Mail" /><a
            href="mailto:hallo@actiontouren.de"
            class="nolink"
          >
            hallo@actiontouren.de
          </a>
        </div>
        <div>
          <img src="@/assets/phone.svg" alt="E-Mail" />
          <strong>0160/98484857</strong>
        </div>
      </div>
      <br />

      <a href="https://@actiontouren.de" target="top" class="nolink web">
        www.actiontouren.de
      </a>
      <span class="desktop">&nbsp;|&nbsp;</span>
      <router-link :to="{ path: '/impressum' }" class="nolink web">
        Impressum
      </router-link>
      <span class="desktop">&nbsp;|&nbsp;</span>
      <router-link :to="{ path: '/datenschutz' }" class="nolink web">
        Datenschutzerklärung
      </router-link>
    </section>

    <section class="logos">
      <img
        src="@/assets/logos.png"
        alt="Gefördert vom Bundesministerium für Familie, Senioren, Frauen und Jugend im Rahmen des Aktionsprogramms Aufhol Paket, Deutsche Kinder- und Jugendstiftung und Auf!Leben"
      />
    </section>
  </footer>
</template>

<script>
export default {
  name: "FooterContainer",
};
</script>

<style lang="css" scoped>
footer {
  margin-bottom: 32px;
}

footer h2 {
  font-size: 1.2rem;
}

.container {
  padding: 24px;
}

.workshops {
  grid-area: workshops;
  background: rgb(225, 225, 225);
  padding: 24px;
  padding-bottom: 36px;
}

.familienprogramm {
  grid-area: familienprogramm;
  background: rgb(235, 235, 235);
  padding: 24px;
  padding-bottom: 36px;
}

.infos {
  grid-area: infos;
  background: rgb(245, 245, 245);
  color: black;
  padding: 24px;
}

.infos h3 {
  font-size: 1.4rem;
}

.map {
  margin-top: 48px;
}

.map img {
  mix-blend-mode: multiply;
}

.logos {
  grid-area: logos;
  background: rgb(255, 255, 255);
  padding: 24px;
}

.logos img {
  width: 100%;
  /* max-height: 120px;
    margin-bottom: 12px; */
}

.weg {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-gap: 12px;
  margin-top: 32px;
}

.weg div {
  display: grid;
  grid-template-columns: 30px auto;
  grid-gap: 6px;
}

.kontakt {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-gap: 12px;
}

.kontakt div {
  display: grid;
  grid-template-columns: 30px auto;
  grid-gap: 6px;
}

.web {
  display: block;
  margin-bottom: 6px;
}

@media screen and (min-width: 600px) {
  footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "workshops workshops familienprogramm familienprogramm"
      "infos1 infos1 infos2 infos2"
      "logos logos logos logos";
  }

  .workshops {
    grid-area: workshops;
    background: rgb(235, 235, 235);
    padding: 24px;
    padding-bottom: 36px;
  }

  .familienprogramm {
    grid-area: familienprogramm;
    background: rgb(235, 235, 235);
    padding: 24px;
    padding-bottom: 36px;
  }

  .infos {
    grid-area: infos;
    background: rgb(245, 245, 245);
    color: black;
    padding: 24px;
  }

  .infos1 {
    grid-area: infos1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }

  .infos1 .map img {
    height: 300px;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  .infos2 {
    grid-area: infos2;
  }
  .infos3 {
    grid-area: infos3;
  }
  .infos4 {
    grid-area: infos4;
  }

  .web {
    display: inline-block;
    margin-bottom: 6px;
  }
}
</style>
