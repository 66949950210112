<template>
  <transition name="fade" mode="out-in">
    <Header v-if="items" />
  </transition>

  <transition name="fade" mode="out-in">
    <main v-if="items">
      <div class="home">
        <Container
          v-for="item in items"
          :key="item.id"
          :item="item"
          ref="container"
        />
      </div>

      <section class="else">
        <Kanu />
        <Weekend />
      </section>
    </main>
  </transition>

  <Footer v-if="items" />
</template>

<script>
import { mapActions } from "vuex";
import Header from "@/components/HeaderHome.vue";
import Footer from "@/components/Footer.vue";
import Kanu from "@/components/KanuContainer.vue";
import Container from "@/components/HomeContainer.vue";
import Weekend from "@/components/Weekend.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    Container,
    Kanu,
    Footer,
    Weekend,
  },
  data() {
    return { items: undefined };
  },
  methods: {
    ...mapActions(["db"]),
    fetch() {
      let payload = { o: { start: 1 } };
      this.db({ table: "festival", payload }).then((res) => {
        this.items = res.data.results;
        this.$nextTick(() => {
          this.animate();
        });
      });
    },
    animate() {
      const container = document.querySelectorAll(".container");
      let count = 0;
      container.forEach((c) => {
        c.classList.add("animate");
        c.style.animationDelay = count * 0.5 + "s";
        count++;
      });
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="css" scoped>
.intro {
  grid-area: intro;
  padding: 24px;
  background: white;
}

.intro h2 {
  font-size: 1.8rem;
}

.intro p {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.rahmen {
  grid-area: rahmen;
  padding-top: 100%;
  padding: 24px;
}

@media screen and (min-width: 600px) {
  .home {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .else {
    display: grid;
    grid-template-areas: "kanu rahmen";
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 980px) {
  .home {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
