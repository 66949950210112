<template>
  <section class="container weekend_event">
    <div v-for="item in items" :key="item.id">
      <div class="date">{{ date(item.start) }} - {{ time(item.start) }}</div>
      <h2>{{ item.title }}</h2>

      <div class="tags">
        <div class="tag">Familienprogramm</div>
        <div class="tag">Eintritt frei!</div>
      </div>
      <div v-html="item.content" class="content"></div>
    </div>

    <div class="link">
      <router-link :to="{ path: '/programm/familienprogramm' }" class="nolink">
        <button class="button" clicker="true">Mehr im Programm!</button>
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FestivalWeekend",
  props: ["festival"],
  data() {
    return {
      items: undefined,
    };
  },
  methods: {
    ...mapActions(["db"]),
    fetch() {
      let payload = {
        l: 1,
        q: {
          start: {
            $gte: this.$dayjs().format("YYYY-MM-DD"),
          },
          is_festival: { $eq: 0 },
        },
      };

      this.db({ table: "events", payload }).then((res) => {
        this.items = res.data.results;
      });
    },
    date(date) {
      return this.$dayjs(date).format("dddd, D. MMMM YYYY");
    },
    time(date) {
      return this.$dayjs(date).format("HH:mm") + " Uhr";
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="css" scoped>
.programmlink {
  position: absolute;
  bottom: 24px;
}
</style>
