import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Home.vue";

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/programm/:filter?",
    name: "programm",
    component: lazyLoad("Programm"),
  },
  {
    path: "/:slug",
    name: "page",
    component: lazyLoad("Page"),
  },
  {
    path: "/fragen",
    name: "faq",
    component: lazyLoad("Fragen"),
  },
  {
    path: "/ups",
    name: "notfound",
    component: lazyLoad("Notfound"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: lazyLoad("Notfound"),
  },
];

// TODO 404

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
