import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/assets/css/style.css";
import "@/assets/css/media.css";
import "@/assets/css/fonts.css";

const app = createApp(App);

// DAYJS
import dayjs from "dayjs"; //import dayjs in your main.js
require("dayjs/locale/de");
dayjs.locale("de");
const weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

app.config.globalProperties.$dayjs = dayjs;

app.use(store).use(router).mount("#app");
