<template>
  <div class="yay">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 918 924"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linejoin: round;
        stroke-miterlimit: 2;
      "
    >
      <g transform="matrix(1,0,0,1,-1674.58,-931.943)">
        <g
          transform="matrix(0.989724,0.142988,-0.142988,0.989724,242.703,-302.371)"
        >
          <path
            d="M2129.81,939.431L2501.43,1118.39L2593.21,1520.52L2336.05,1843L1923.58,1843L1666.41,1520.52L1758.19,1118.39L2129.81,939.431Z"
            style="fill: rgb(73, 52, 83)"
          />
        </g>
        <g
          transform="matrix(0.657548,-0.587654,0.587654,0.657548,-105.887,1716.62)"
        >
          <g
            transform="matrix(0.158205,0.0423909,-0.0423909,0.158205,1745.06,912.664)"
          >
            <path
              d="M1051.65,1719.64L963.215,1719.64L963.215,1173.88L1051.65,1173.88L1051.65,1420.07L1132.92,1413.7L1244.46,1173.88L1345.65,1173.88L1211,1447.96L1351.22,1719.64L1248.44,1719.64L1132.92,1491.78L1051.65,1497.35L1051.65,1719.64Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M1513.76,1604.12C1531.82,1635.45 1566.34,1651.12 1617.33,1651.12C1668.32,1651.12 1702.72,1635.72 1720.51,1604.91C1738.3,1574.11 1747.2,1522.45 1747.2,1449.95C1747.2,1377.45 1738.04,1324.73 1719.71,1291.8C1701.39,1258.87 1667.26,1242.4 1617.33,1242.4C1567.4,1242.4 1533.14,1258.87 1514.55,1291.8C1495.96,1324.73 1486.67,1377.18 1486.67,1449.15C1486.67,1521.12 1495.7,1572.78 1513.76,1604.12ZM1789.43,1661.48C1757.03,1706.1 1699.66,1728.41 1617.33,1728.41C1535,1728.41 1477.37,1705.96 1444.44,1661.08C1411.51,1616.2 1395.04,1545.69 1395.04,1449.55C1395.04,1353.41 1411.64,1281.97 1444.84,1235.23C1478.04,1188.49 1535.53,1165.12 1617.33,1165.12C1699.13,1165.12 1756.49,1188.36 1789.43,1234.83C1822.36,1281.31 1838.82,1352.88 1838.82,1449.55C1838.82,1546.22 1822.36,1616.86 1789.43,1661.48Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M2101.74,1242.4C2034.29,1242.4 2000.56,1266.04 2000.56,1313.31C2000.56,1337.74 2008.53,1355.14 2024.46,1365.5C2040.4,1375.85 2075.59,1388.6 2130.03,1403.74C2184.47,1418.88 2222.85,1436.93 2245.16,1457.91C2267.46,1478.9 2278.62,1511.96 2278.62,1557.11C2278.62,1614.47 2262.28,1657.36 2229.62,1685.78C2196.95,1714.2 2152.47,1728.41 2096.17,1728.41C2051.02,1728.41 2001.09,1723.09 1946.38,1712.47L1917.7,1706.89L1926.46,1635.99C1998.17,1645.55 2052.88,1650.33 2090.59,1650.33C2156.45,1650.33 2189.39,1621.11 2189.39,1562.69C2189.39,1539.85 2181.95,1522.98 2167.08,1512.09C2152.2,1501.2 2118.74,1489.25 2066.69,1476.24C2014.63,1463.23 1975.86,1445.3 1950.37,1422.46C1924.87,1399.62 1912.12,1363.5 1912.12,1314.11C1912.12,1264.71 1928.19,1227.39 1960.32,1202.16C1992.46,1176.93 2036.94,1164.32 2093.78,1164.32C2134.68,1164.32 2183.01,1169.1 2238.78,1178.66L2266.67,1183.44L2259.5,1255.15C2185.14,1246.65 2132.55,1242.4 2101.74,1242.4Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M2321.64,1252.76L2321.64,1173.88L2720.01,1173.88L2720.01,1252.76L2566.24,1252.76L2566.24,1719.64L2477,1719.64L2477,1252.76L2321.64,1252.76Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M2793.31,1719.64L2793.31,1173.88L3137.49,1173.88L3137.49,1251.16L2881.74,1251.16L2881.74,1405.73L3089.69,1405.73L3089.69,1482.22L2881.74,1482.22L2881.74,1641.56L3137.49,1641.56L3137.49,1719.64L2793.31,1719.64Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M3233.9,1719.64L3233.9,1173.88L3388.47,1173.88L3554.18,1641.56L3566.14,1641.56L3566.14,1173.88L3654.57,1173.88L3654.57,1719.64L3503.19,1719.64L3333.49,1251.16L3322.34,1251.16L3322.34,1719.64L3233.9,1719.64Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M4086.4,1719.64L3780.46,1719.64L3780.46,1173.88L3868.89,1173.88L3868.89,1640.77L4086.4,1640.77L4086.4,1719.64Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M4244.15,1604.12C4262.21,1635.45 4296.74,1651.12 4347.73,1651.12C4398.72,1651.12 4433.11,1635.72 4450.91,1604.91C4468.7,1574.11 4477.6,1522.45 4477.6,1449.95C4477.6,1377.45 4468.43,1324.73 4450.11,1291.8C4431.78,1258.87 4397.66,1242.4 4347.73,1242.4C4297.8,1242.4 4263.54,1258.87 4244.95,1291.8C4226.36,1324.73 4217.07,1377.18 4217.07,1449.15C4217.07,1521.12 4226.1,1572.78 4244.15,1604.12ZM4519.82,1661.48C4487.42,1706.1 4430.06,1728.41 4347.73,1728.41C4265.4,1728.41 4207.77,1705.96 4174.84,1661.08C4141.91,1616.2 4125.44,1545.69 4125.44,1449.55C4125.44,1353.41 4142.04,1281.97 4175.24,1235.23C4208.43,1188.49 4265.93,1165.12 4347.73,1165.12C4429.53,1165.12 4486.89,1188.36 4519.82,1234.83C4552.75,1281.31 4569.22,1352.88 4569.22,1449.55C4569.22,1546.22 4552.75,1616.86 4519.82,1661.48Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M4832.14,1242.4C4764.69,1242.4 4730.96,1266.04 4730.96,1313.31C4730.96,1337.74 4738.92,1355.14 4754.86,1365.5C4770.79,1375.85 4805.98,1388.6 4860.43,1403.74C4914.87,1418.88 4953.24,1436.93 4975.55,1457.91C4997.86,1478.9 5009.02,1511.96 5009.02,1557.11C5009.02,1614.47 4992.68,1657.36 4960.02,1685.78C4927.35,1714.2 4882.87,1728.41 4826.56,1728.41C4781.42,1728.41 4731.49,1723.09 4676.78,1712.47L4648.1,1706.89L4656.86,1635.99C4728.57,1645.55 4783.28,1650.33 4820.99,1650.33C4886.85,1650.33 4919.78,1621.11 4919.78,1562.69C4919.78,1539.85 4912.35,1522.98 4897.47,1512.09C4882.6,1501.2 4849.14,1489.25 4797.09,1476.24C4745.03,1463.23 4706.26,1445.3 4680.76,1422.46C4655.27,1399.62 4642.52,1363.5 4642.52,1314.11C4642.52,1264.71 4658.59,1227.39 4690.72,1202.16C4722.86,1176.93 4767.34,1164.32 4824.17,1164.32C4865.07,1164.32 4913.41,1169.1 4969.18,1178.66L4997.07,1183.44L4989.89,1255.15C4915.53,1246.65 4862.95,1242.4 4832.14,1242.4Z"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
          <g
            transform="matrix(0.152907,0.0586956,-0.0586956,0.152907,1695.39,1213.08)"
          >
            <path
              d="M1302.62,1719.64L1113.8,1719.64L1113.8,1173.88L1302.62,1173.88C1386.55,1173.88 1443.91,1195.13 1474.72,1237.62C1505.52,1280.11 1520.93,1346.77 1520.93,1437.6C1520.93,1483.81 1517.87,1522.98 1511.77,1555.12C1505.66,1587.25 1494.9,1616.33 1479.5,1642.36C1449.22,1693.88 1390.26,1719.64 1302.62,1719.64ZM1429.3,1437.6C1429.3,1371.74 1421.07,1324.2 1404.6,1294.98C1388.14,1265.77 1354.14,1251.16 1302.62,1251.16L1202.24,1251.16L1202.24,1641.56L1302.62,1641.56C1355.21,1641.56 1390.26,1622.17 1407.79,1583.4C1416.29,1563.75 1422,1542.77 1424.92,1520.46C1427.84,1498.15 1429.3,1470.53 1429.3,1437.6Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M1712.94,1525.24L1712.94,1719.64L1624.5,1719.64L1624.5,1173.88L1831.65,1173.88C1954.88,1173.88 2016.49,1231.78 2016.49,1347.57C2016.49,1425.12 1986.75,1477.7 1927.26,1505.32L2017.29,1719.64L1920.09,1719.64L1841.21,1525.24L1712.94,1525.24ZM1925.67,1348.37C1925.67,1283.03 1894.33,1250.37 1831.65,1250.37L1712.94,1250.37L1712.94,1448.75L1833.25,1448.75C1865.12,1448.75 1888.49,1439.59 1903.36,1421.27C1918.23,1402.94 1925.67,1378.64 1925.67,1348.37Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M2064.3,1719.64L2198.95,1173.88L2375.02,1173.88L2510.47,1719.64L2421.23,1719.64L2391.75,1602.52L2182.21,1602.52L2152.74,1719.64L2064.3,1719.64ZM2267.46,1247.98L2199.74,1524.44L2374.23,1524.44L2307.3,1247.98L2267.46,1247.98Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M2668.22,1545.16C2668.22,1615.8 2705.4,1651.12 2779.76,1651.12C2854.12,1651.12 2891.3,1615.8 2891.3,1545.16L2891.3,1173.88L2980.54,1173.88L2980.54,1543.56C2980.54,1607.83 2963.67,1654.71 2929.95,1684.19C2896.22,1713.67 2846.02,1728.41 2779.36,1728.41C2712.7,1728.41 2662.64,1713.67 2629.18,1684.19C2595.72,1654.71 2578.99,1607.83 2578.99,1543.56L2578.99,1173.88L2668.22,1173.88L2668.22,1545.16Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M3261.78,1242.4C3194.33,1242.4 3160.6,1266.04 3160.6,1313.31C3160.6,1337.74 3168.57,1355.14 3184.5,1365.5C3200.44,1375.85 3235.62,1388.6 3290.07,1403.74C3344.51,1418.88 3382.89,1436.93 3405.2,1457.91C3427.51,1478.9 3438.66,1511.96 3438.66,1557.11C3438.66,1614.47 3422.33,1657.36 3389.66,1685.78C3356.99,1714.2 3312.51,1728.41 3256.21,1728.41C3211.06,1728.41 3161.13,1723.09 3106.42,1712.47L3077.74,1706.89L3086.5,1635.99C3158.21,1645.55 3212.92,1650.33 3250.63,1650.33C3316.49,1650.33 3349.43,1621.11 3349.43,1562.69C3349.43,1539.85 3341.99,1522.98 3327.12,1512.09C3312.24,1501.2 3278.78,1489.25 3226.73,1476.24C3174.68,1463.23 3135.9,1445.3 3110.41,1422.46C3084.91,1399.62 3072.16,1363.5 3072.16,1314.11C3072.16,1264.71 3088.23,1227.39 3120.37,1202.16C3152.5,1176.93 3196.98,1164.32 3253.82,1164.32C3294.72,1164.32 3343.05,1169.1 3398.82,1178.66L3426.71,1183.44L3419.54,1255.15C3345.18,1246.65 3292.59,1242.4 3261.78,1242.4Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M3694.41,1242.4C3626.95,1242.4 3593.22,1266.04 3593.22,1313.31C3593.22,1337.74 3601.19,1355.14 3617.13,1365.5C3633.06,1375.85 3668.25,1388.6 3722.69,1403.74C3777.14,1418.88 3815.51,1436.93 3837.82,1457.91C3860.13,1478.9 3871.28,1511.96 3871.28,1557.11C3871.28,1614.47 3854.95,1657.36 3822.28,1685.78C3789.62,1714.2 3745.14,1728.41 3688.83,1728.41C3643.68,1728.41 3593.76,1723.09 3539.05,1712.47L3510.37,1706.89L3519.13,1635.99C3590.83,1645.55 3645.54,1650.33 3683.26,1650.33C3749.12,1650.33 3782.05,1621.11 3782.05,1562.69C3782.05,1539.85 3774.61,1522.98 3759.74,1512.09C3744.87,1501.2 3711.41,1489.25 3659.35,1476.24C3607.3,1463.23 3568.53,1445.3 3543.03,1422.46C3517.53,1399.62 3504.79,1363.5 3504.79,1314.11C3504.79,1264.71 3520.86,1227.39 3552.99,1202.16C3585.12,1176.93 3629.61,1164.32 3686.44,1164.32C3727.34,1164.32 3775.68,1169.1 3831.45,1178.66L3859.33,1183.44L3852.16,1255.15C3777.8,1246.65 3725.22,1242.4 3694.41,1242.4Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M3966.89,1719.64L3966.89,1173.88L4311.08,1173.88L4311.08,1251.16L4055.33,1251.16L4055.33,1405.73L4263.27,1405.73L4263.27,1482.22L4055.33,1482.22L4055.33,1641.56L4311.08,1641.56L4311.08,1719.64L3966.89,1719.64Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M4407.48,1719.64L4407.48,1173.88L4562.05,1173.88L4727.77,1641.56L4739.72,1641.56L4739.72,1173.88L4828.16,1173.88L4828.16,1719.64L4676.78,1719.64L4507.07,1251.16L4495.92,1251.16L4495.92,1719.64L4407.48,1719.64Z"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
          <g
            transform="matrix(0.15577,0.0506126,-0.0506126,0.15577,1685.3,1052.1)"
          >
            <path
              d="M2330.8,1545.16C2330.8,1615.8 2367.99,1651.12 2442.35,1651.12C2516.71,1651.12 2553.89,1615.8 2553.89,1545.16L2553.89,1173.88L2643.12,1173.88L2643.12,1543.56C2643.12,1607.83 2626.26,1654.71 2592.53,1684.19C2558.8,1713.67 2508.61,1728.41 2441.95,1728.41C2375.29,1728.41 2325.23,1713.67 2291.76,1684.19C2258.3,1654.71 2241.57,1607.83 2241.57,1543.56L2241.57,1173.88L2330.8,1173.88L2330.8,1545.16Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M2764.23,1719.64L2764.23,1173.88L2918.79,1173.88L3084.51,1641.56L3096.46,1641.56L3096.46,1173.88L3184.9,1173.88L3184.9,1719.64L3033.52,1719.64L2863.82,1251.16L2852.66,1251.16L2852.66,1719.64L2764.23,1719.64Z"
              style="fill: white; fill-rule: nonzero"
            />
            <path
              d="M3499.61,1719.64L3310.78,1719.64L3310.78,1173.88L3499.61,1173.88C3583.53,1173.88 3640.9,1195.13 3671.7,1237.62C3702.51,1280.11 3717.91,1346.77 3717.91,1437.6C3717.91,1483.81 3714.86,1522.98 3708.75,1555.12C3702.64,1587.25 3691.89,1616.33 3676.48,1642.36C3646.21,1693.88 3587.25,1719.64 3499.61,1719.64ZM3626.29,1437.6C3626.29,1371.74 3618.06,1324.2 3601.59,1294.98C3585.12,1265.77 3551.13,1251.16 3499.61,1251.16L3399.22,1251.16L3399.22,1641.56L3499.61,1641.56C3552.19,1641.56 3587.25,1622.17 3604.78,1583.4C3613.28,1563.75 3618.99,1542.77 3621.91,1520.46C3624.83,1498.15 3626.29,1470.53 3626.29,1437.6Z"
              style="fill: white; fill-rule: nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "YayContainer",
};
</script>

<style lang="css" scoped>
.yay {
  position: absolute;
  width: 100px;
  height: 100px;
  right: -10px;
  top: 40px;
  z-index: 99999999;
  display: grid;
  place-items: center;
  transition: transform 1s;
  animation-name: yay;
  animation-duration: 1s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

@keyframes yay {
  from {
    transform: scale(3) rotate(-180deg);
  }
  to {
    transform: scale(1) rotate(35deg);
  }
}

@media screen and (min-width: 600px) {
  .yay {
    top: unset;
    bottom: -10vw;
    right: -20px;
    width: 30vw;
    height: 30vw;
    max-width: 300px;
    max-height: 300px;
  }
}

@media screen and (min-width: 900px) {
  .yay {
    top: unset;
    bottom: -5vw;
    right: 0px;
    width: 25vw;
    height: 25vw;
    max-width: 300px;
    max-height: 300px;
  }
}
</style>
