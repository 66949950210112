import { createStore } from "vuex";
import axios from "axios";

if (process.env.VUE_APP_API_DB) {
  console.log("API: DB: " + process.env.VUE_APP_API_DB);
  axios.defaults.headers.common["APIDB"] = process.env.VUE_APP_API_DB;
}

export default createStore({
  state: {
    loading: false,
    Clicker: { event: undefined, route: false },
  },
  getters: {},
  mutations: {},
  actions: {
    // DB GET POST UPDATE DELETE
    async db({ state }, { table, action = "get", payload }) {
      state.loading = true;
      const id = payload?.id ? payload.id : "";
      if (table) table = table + "/";
      if (!table) table = "";

      if (action == "get") payload = { params: payload };
      console.log("API:", action.toUpperCase(), table);
      const req = axios[action](process.env.VUE_APP_API + table + id, payload)
        .then((res) => {
          state.loading = false;
          return res;
        })
        .catch((err) => {
          console.error(err.response.data);
          return err.response;
        });

      return req;
    },
  },
});
