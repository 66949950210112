<template>
  <nav>
    <router-link to="/" clicker="true">Startseite</router-link>
    <router-link :to="{ path: '/programm' }" clicker="true">
      Programm
    </router-link>
    <!-- <router-link :to="{ path: '/fragen' }" clicker="true">Blog</router-link> -->
    <router-link :to="{ path: '/fragen' }" clicker="true">
      Fragen?
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "NavigationView",
};
</script>

<style lang="css" scoped>
nav {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
  background: #333;
  z-index: 10000;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}

nav a {
  transition: all 0.6s;
  box-sizing: border-box;
  padding: 12px 0;
  background: #333;
  color: white;
  border: none;
  white-space: nowrap;
  user-select: none;
}

nav a:hover {
  background: white;
  color: #333;
}

.router-link-active {
  background: white;
  color: #333;
}

@media screen and (min-width: 600px) {
  nav {
    /* position: sticky; */
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    font-size: 1.8rem;
    display: grid;
    grid-template-columns: repeat(5, min-content);
    background: #333;
    z-index: 10000;
  }

  nav a {
    transition: all 0.6s;
    padding: 12px 24px;
    background: #333;
    color: white;
    border: none;
    white-space: nowrap;
    user-select: none;
  }

  nav a:hover {
    background: white;
    color: #333;
  }
}
</style>
